<template>
	<div class="bullMarket-recharge">
		<div class="el-content">
			<a-space>
				<a-select v-model:value="search.option_status" style="width:180px" placeholder="请选择查询状态">
					<a-select-option value="">请选择查询状态</a-select-option>
					<a-select-option value="0">处理中</a-select-option>
					<a-select-option value="1">成功</a-select-option>
					<a-select-option value="2">失败</a-select-option>
					<a-select-option value="4">人工查错</a-select-option>
				</a-select>
				<a-select v-model:value="search.tr_status" style="width:180px" placeholder="Ceb转入电子账户">
					<a-select-option value="" >Ceb转入电子账户</a-select-option>
					<a-select-option value="0" >未处理</a-select-option>
					<a-select-option value="1" >成功</a-select-option>
					<a-select-option value="2" >失败</a-select-option>
					<a-select-option value="4" >人工查错</a-select-option>
				</a-select>
				<a-select v-model:value="search.tr_check_status" style="width:180px" placeholder="Ceb转入电子账户查证">
					<a-select-option value="">Ceb转入电子账户查证</a-select-option>
					<a-select-option value="0">未处理</a-select-option>
					<a-select-option value="1">成功</a-select-option>
					<a-select-option value="2">失败</a-select-option>
					<a-select-option value="4">人工查错</a-select-option>
				</a-select>
				<a-select v-model:value="search.tr2_status" style="width:180px" placeholder="Ceb转出电子账户">
					<a-select-option value="">Ceb转出电子账户</a-select-option>
					<a-select-option value="0">未处理</a-select-option>
					<a-select-option value="1">成功</a-select-option>
					<a-select-option value="2">失败</a-select-option>
					<a-select-option value="4">人工查错</a-select-option>
				</a-select>
				<a-select v-model:value="search.tr2_check_status" style="width:180px" placeholder="Ceb转出电子账户查证">
					<a-select-option value="">Ceb转出电子账户查证</a-select-option>
					<a-select-option value="0">未处理</a-select-option>
					<a-select-option value="1">成功</a-select-option>
					<a-select-option value="2">失败</a-select-option>
					<a-select-option value="4">人工查错</a-select-option>
				</a-select>
				<a-input-search style="width: 400px;"
					v-model:value="search.tr_id" 
					placeholder="请输入查询关键字" 
					enter-button 
					@search="getBullRecharge(1,info.limit)" />
			</a-space>
		</div>
		<div class="el-content">
			<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'交易单号',dataIndex:'tr_id'},
				{title:'充值用户',dataIndex:'user',slots:{customRender:'user'}},
				{title:'充值金额',dataIndex:'price',slots:{customRender:'price'}},
				{title:'Ceb转入电子账户',dataIndex:'ceb_in',slots:{customRender:'ceb_in'}},
				{title:'Ceb转出公账',dataIndex:'ceb_out',slots:{customRender:'ceb_out'}},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'充值时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #user="{record}">
					<div style="display: flex;align-items: center;" v-if="record.account">
						<a-avatar size="medium" :src="record.account.avatar_url"/>
						<div style="margin-left: 4px;">
							<div><span>{{record.account.nickname}}</span></div>
							<div><span class="f12">user_id:</span>{{record.account.user_id}}</div>
						</div>
					</div>
				</template>
				<template #price="{record}">
					<div><span class="f12">充值金额：</span>{{record.amount}}元</div>
					<div><span class="f12">实际到账：</span>{{record.tr2_amount}}元</div>
				</template>
				<template #ceb_in="{record}">
					<div class="mb12">
						<span class="f12">操作：</span>
						<a-tag :color="getBullWithdrawStatusType(record.tr_status)">
							{{getBullWithdrawStatus(record.tr_status)}}
						</a-tag>
					</div>
					<div>
						<span class="f12">查证：</span>
						<a-tag :color="getBullWithdrawStatusType(record.tr_check_status)">
							{{getBullWithdrawStatus(record.tr_check_status)}}
						</a-tag>
					</div>
				</template>
				<template #ceb_out="{record}">
					<div class="mb12">
						<span class="f12">操作：</span>
						<a-tag :color="getBullWithdrawStatusType(record.tr2_status)">
							{{getBullWithdrawStatus(record.tr2_status)}}
						</a-tag>
					</div>
					<div>
						<span class="f12">查证：</span>
						<a-tag :color="getBullWithdrawStatusType(record.tr2_check_status)">
							{{getBullWithdrawStatus(record.tr2_check_status)}}
						</a-tag>
					</div>
				</template>
				<template #status="{record}">
					<a-tag color="#999" v-if="record.option_status == 0">处理中</a-tag>
					<a-tag color="#00CC66" v-if="record.option_status == 1">成功</a-tag>
					<a-tag color="#FF0066" v-if="record.option_status == 2">失败</a-tag>
					<a-tag color="#FF0066" v-if="record.option_status == 4">人工查错</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<a-button size="small" type="danger" @click="retry(record.id)">重试</a-button>
						<router-link :to="{path:'/adhibition/bullMarket/supervise',query:{re_id:record.id,re_ty:'recharge'}}">
							<a-button size="small" type="primary">关联</a-button>
						</router-link>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBullRecharge(info.page,e)}"
					@change="(e)=>{getBullRecharge(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import bullModel from '@/api/addons/bull'
export default{
	computed:{
		getBullWithdrawStatus(){
			return function(s){
				if( s == 0 ) return '未处理'
				if( s == 1 ) return '成功'
				if( s == 2 ) return '失败'
			}
		},
		getBullWithdrawStatusType(){
			return function(s){
				if( s == 0 ) return '#999'
				if( s == 1 ) return '#00CC66'
				if( s == 2 ) return '#FF0066'
			}
		}
	},
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			search:{
				tr_id:'',
				option_status:'',
				tr_status:'',
				tr2_status:'',
				tr_check_status:'',
				tr2_check_status:'',
			}
		})
		getBullRecharge(1,state.info.limit)

		function getBullRecharge(page,limit){
			bullModel.getBullRecharge(page,limit,state.search,res=>state.info = {limit,...res})
		}

		const retry = id=>bullModel.bullRechargeReTry(id,()=>{
			getBullRecharge(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getBullRecharge,
			retry
		}
	}
}
</script>

<style lang="scss">
	
</style>
